<template>
    <div class="page">
        <div class="content">
            <div class="left-box">
                <div class="welcome-box">
                    <div class="head-box">
                        <div class="date-box">
                            <Icon class="icon" type="md-calendar" size="40" />
                            <div class="name">今天是：</div>
                            <div class="value">{{ $core.formatDate(new Date(), "yyyy年MM月dd日") }}</div>
                        </div>

                        <div class="message"></div>
                    </div>

                    <div class="text">欢迎登录{{ orgName }}“社区随约服务网上驿站”运营管理平台！</div>
                </div>

                <div class="grid-box">
                    <div class="item-box block" v-for="(item, idx) in modules" :key="idx">
                        <div class="head-box">
                            <div class="title-box">
                                <span>{{ item.title }}</span>
                            </div>
                            <div class="date">数据更新时间：{{ nowDateStr }} 00:00</div>
                            <div class="btn" v-if="item.path" @click="$router.push(item.path)">
                                <span>详情</span>
                                <Icon class="icon" type="ios-arrow-forward" />
                            </div>
                        </div>
                        <div class="stats-box">
                            <div class="value-box">
                                <NumberRoll :number="item.value" :time="2000"></NumberRoll>
                                <p class="unit">{{ item.unit }}</p>
                            </div>
                            <div class="gap">
                                <p class="name">较1日前</p>
                                <p class="value up" :style="{ color: item.valueDayAgo >= 0 ? '#06a561' : '#F0142F' }">{{ item.valueDayAgo >= 0 ? "+" : "" }}{{ item.valueDayAgo || 0 }}{{ item.unit }}</p>
                            </div>
                            <div class="gap">
                                <p class="name">较15日前</p>
                                <p class="value up" :style="{ color: item.value15Ago >= 0 ? '#06a561' : '#F0142F' }">{{ item.value15Ago >= 0 ? "+" : "" }}{{ item.value15Ago || 0 }}{{ item.unit }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="condition-box block">
                    <div class="head-box">
                        <div class="title-box">
                            <span>{{ orgName }}社区推广情况</span>
                        </div>
                        <div class="date">数据更新时间：{{ nowDateStr }} 00:00</div>
                        <div class="btn" v-if="false">
                            <span>详情</span>
                            <Icon class="icon" type="ios-arrow-forward" />
                        </div>
                    </div>
                    <div class="item-box">
                        <div class="name-box">开通随约服务社区累计数</div>
                        <div class="value-box">
                            <div class="compare-box">
                                <div class="item">
                                    <div class="value">
                                        <span>{{ statStreetPromotion.suiyueServiceNum || 0 }}</span>
                                        <p class="unit">个</p>
                                    </div>
                                    <div class="name">开通数</div>
                                </div>
                                <div class="symbol">/</div>
                                <div class="item">
                                    <div class="value">
                                        <span>{{ statStreetPromotion.communityNum || 0 }}</span>
                                        <p class="unit">个</p>
                                    </div>
                                    <div class="name">总数</div>
                                </div>
                            </div>
                            <div class="explain">开通率：{{ statStreetPromotion.takeRate || "0.00" }}%</div>
                        </div>
                    </div>
                    <div class="thereinto">其中：</div>
                    <div class="item-box">
                        <div class="name-box">试点社区</div>
                        <div class="value-box">
                            <div class="value">
                                <span>{{ statStreetPromotion.pilotCommunityNum || 0 }}</span>
                                <p class="unit">个</p>
                            </div>
                        </div>
                    </div>
                    <div class="item-box">
                        <div class="name-box">试用社区情况</div>
                        <div class="value-box">
                            <div class="compare-box">
                                <div class="item">
                                    <div class="value">
                                        <span>{{ statStreetPromotion.applyServiceNum || 0 }}</span>
                                        <p class="unit">个</p>
                                    </div>
                                    <div class="name">已应用数</div>
                                </div>
                                <div class="symbol">/</div>
                                <div class="item">
                                    <div class="value">
                                        <span>{{ statStreetPromotion.trialCommunity || 0 }}</span>
                                        <p class="unit">个</p>
                                    </div>
                                    <div class="name">试用总数</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rankings-box block">
                    <div class="head-box">
                        <div class="title-box">
                            <span>社区注册率排行（前 6 位）</span>
                        </div>
                        <div class="date">数据更新时间：{{ nowDateStr }} 00:00</div>
                        <div class="btn" @click="$router.push('/managsystemregistrationranking')">
                            <span>详情</span>
                            <Icon class="icon" type="ios-arrow-forward" />
                        </div>
                    </div>
                    <div class="table-box">
                        <Table size="small" :columns="tableColumn" :data="tableData" height="200"></Table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NumberRoll from "./childrens/NumberRoll.vue"

import Street from "./utils/street"

export default {
    components: { NumberRoll },

    data() {
        let date = new Date()
        date.setDate(date.getDate() - 1)

        return {
            date: date,
            nowDateStr: this.$core.formatDate(new Date(), "yyyy-MM-dd"),
            modules: [
                {
                    title: "累计访问用户数据",
                    key: "statRegister",
                    day1: "registerNumDayAgo",
                    day15: "registerNum15Ago",
                    unit: "人",
                    path: "/managsystemuserdata",
                },
                {
                    title: "累计居民接受服务数据",
                    key: "statViewNum",
                    day1: "viewNumDayAgo",
                    day15: "viewNum15Ago",
                    unit: "人次",
                    path: "/managsystemoperationcontent",
                },
                {
                    title: "近15日未更新内容的社区数",
                    reqKey: "notUpdate15DayCommunity",
                    key: "last15DayPubNum",
                    unit: "个",
                },
                {
                    title: "有空白栏目的社区数",
                    key: "blankColumnCommunityNum",
                    day1: "blankColumnCommunityNumDayAgo",
                    day15: "blankColumnCommunityNum15Ago",
                    unit: "个",
                },
            ],
            tableColumn: [
                {
                    title: "社区",
                    key: "orgName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "注册率",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", (params.row.registrationRate || "0.00") + "%")
                    },
                },
                {
                    title: "注册率排行",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", "No." + (params.index + 1))
                    },
                },
            ],
            orgName: "",
            tableData: [],
            info: {},
            statStreetPromotion: {},
        }
    },

    async created() {
        let street = await Street.getInfo()
        this.orgName = street.orgName
        this.orgPath = street.orgPath

        await this.getCountDate()
        await this.getStatStreetPromotion()
        await this.getCommunityData()
    },

    methods: {
        /**
         * 获取统计数据
         */
        getCountDate() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            const fields = []

            for (let i = 0, l = this.modules; i < l.length; i++) {
                let v = l[i]
                v.reqKey ? fields.push(v.reqKey) : fields.push(v.key)
                v.day1 && fields.push(v.day1)
                v.day15 && fields.push(v.day15)
            }

            // 有空白栏目的社区
            fields.push("blankColumnCommunity")

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listValueCountByCode",
                {
                    dataScopeId: this.orgPath,
                    statDate: this.$core.formatDate(this.date, "yyyy-MM-dd"),
                    selectedField: fields,
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        let data = res.data

                        for (let i = 0, l = this.modules; i < l.length; i++) {
                            let v = l[i]
                            let val = data[v.key] || 0
                            let valDay1 = data[v.day1 || v.key + "DayAgo"] || 0
                            let valDay15 = data[v.day15 || v.key + "15Ago"] || 0

                            this.$set(v, "value", val)
                            this.$set(v, "value15Ago", val - valDay15)
                            this.$set(v, "valueDayAgo", val - valDay1)
                        }

                        this.info = data
                    }
                })
                .finally(this.$Message.destroy)
        },

        getStatStreetPromotion() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/statStreetPromotion",
                {
                    dataScopeId: this.orgPath,
                    statDate: this.$core.formatDate(this.date, "yyyy-MM-dd"),
                    selectedField: ["onlineStatus", "onlineType", "applyService", "suiyueService"],
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.statStreetPromotion = res.data
                    }
                })
                .finally(this.$Message.destroy)
        },

        /**
         * 获取多社区数据
         */
        getCommunityData() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listCommunityValue",
                {
                    dataScopeId: this.orgPath, // 范围编码
                    statDate: this.$core.formatDate(this.date, "yyyy-MM-dd"), // 统计时间
                    selectedField: ["orgCode", "orgName", "registrationRate", "statRegister", "shouldMember"], // 查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.tableData = res.dataList
                            ?.sort((a, b) => {
                                let na = Number(a.registrationRate)
                                let nb = Number(b.registrationRate)

                                if (na === nb) return 0
                                return na < nb ? 1 : -1
                            })
                            .slice(0, 6)
                    }
                })
                .finally(this.$Message.destroy)
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    width: 100%;
    background: #f8f8f8;
    position: relative;
    min-height: 100%;

    .content {
        max-width: 1300px;
        min-width: 1100px;
        padding: 20px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .left-box {
        width: calc(70% - 5px);

        .welcome-box {
            width: 100%;
            padding: 20px;
            background: #fff;
            border-radius: 6px;

            .head-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .date-box {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    font-size: 20px;

                    .icon {
                        margin: 0 25px 0 10px;
                    }

                    .name {
                        padding: 10px 10px 10px 30px;
                        background: #0fc87a;
                        color: #fff;
                        border-radius: 6px 0 0 6px;
                    }

                    .value {
                        padding: 10px 20px;
                        background: #f3f3f3;
                        color: #333;
                        border-radius: 0 6px 6px 0;
                    }
                }
            }

            .text {
                margin-top: 20px;
                font-size: 20px;
                font-weight: bold;
                color: #06a561;
            }
        }

        .grid-box {
            width: 100%;
            margin: 10px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .block {
                width: calc(50% - 5px);
                box-sizing: border-box;
                margin-top: 10px;

                &:nth-child(-n + 2) {
                    margin-top: 0;
                }

                .stats-box {
                    margin-top: 10px;
                    padding: 20px;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid #f3f3f3;
                    background: #fafafa;

                    .value-box {
                        margin-left: 20px;
                        font-size: 50px;
                        line-height: 1;
                        display: flex;
                        align-items: flex-end;
                        color: #57a3f3;
                        margin-bottom: 10px;

                        .unit {
                            margin-left: 5px;
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }

                    .gap {
                        margin-top: 5px;
                        display: flex;
                        align-items: center;
                        color: #444444;

                        .name {
                            margin-left: 50px;
                            width: 60px;
                            text-align: right;
                        }

                        .value {
                            margin-left: 20px;
                            min-width: 100px;

                            &.up {
                                color: #f0142f;
                            }

                            &.down {
                                color: #14f06c;
                            }
                        }
                    }
                }
            }
        }
    }

    .right-box {
        width: calc(30% - 5px);

        .condition-box {
            > .item-box {
                width: 100%;
                margin-top: 10px;
                display: flex;

                .name-box {
                    width: 40%;
                    padding: 0 10px;
                    border-radius: 6px 0 0 6px;
                    background: #0fc87a;
                    color: #fff;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                }

                .value-box {
                    width: 60%;
                    padding: 10px;
                    background: #f3f3f3;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 0 6px 6px 0;

                    .value {
                        display: flex;
                        align-items: flex-end;
                        font-size: 30px;
                        line-height: 1;
                        color: #333;

                        .unit {
                            font-size: 12px;
                            line-height: 17px;
                            margin-left: 5px;
                        }
                    }

                    .compare-box {
                        display: flex;
                        align-items: center;

                        .item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .name {
                                font-size: 12px;
                                color: #666;
                            }
                        }

                        .symbol {
                            font-size: 50px;
                            line-height: 50px;
                            margin: 0 10px;
                            font-weight: 300;
                        }
                    }

                    .explain {
                        margin-top: 5px;
                        color: #0fc87a;
                        font-size: 12px;
                    }
                }
            }

            .thereinto {
                margin: 10px;
            }
        }

        .rankings-box {
            margin-top: 10px;

            .table-box {
                width: 100%;
                margin-top: 10px;
                height: 200px;
                border: 1px solid #f3f3f3;
                overflow: hidden;
            }
        }
    }

    .block {
        padding: 10px 20px 20px;
        background: #fff;
        border-radius: 6px;

        > .head-box {
            position: relative;
            padding-right: 80px;

            .title-box {
                font-size: 16px;
                color: #333;
            }

            .date {
                font-size: 12px;
                color: #888;
            }

            .btn {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                cursor: pointer;

                .icon {
                    margin-left: 5px;
                }
            }
        }
    }
}
</style>
